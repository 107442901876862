// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'admin_user'
  }, {
    prop: 'automated_order_processing',
    back: true
  }, {
    prop: 'global_labels'
  }, {
    prop: 'hide_sold_count'
  }, {
    prop: 'supported_item_types'
  }, {
    prop: 'logo',
    image: true
  }, {
    prop: 'mini_page'
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'online',
    back: true
  }, {
    prop: 'paynow_phone'
  }, {
    prop: 'paynow_wechat'
  }, {
    prop: 'phone_number'
  }, {
    prop: 'poiable'
  }, {
    prop: 'poiable_type'
  }, {
    prop: 'proprietary',
    back: true
  }, {
    prop: 'rule'
  }, {
    prop: 'rule_en'
  }, {
    prop: 'searchable',
    back: true
  }, {
    prop: 'shop_category_items_sorting_method'
  }, {
    prop: 'sms_notification',
    back: true
  }, {
    prop: 'sms_number'
  }, {
    prop: 'sub_domain'
  }],
  deliveryInformation: [{
    prop: 'delivery_time_slots'
  }, {
    prop: 'delivery_tips'
  }, {
    prop: 'delivery_tips_en'
  }, {
    prop: 'delivery_weekdays'
  }, {
    prop: 'driver_instruction'
  }, {
    prop: 'free_delivery_amount_f'
  }, {
    prop: 'mini_delivery_amount_f'
  }, {
    prop: 'partner_email'
  }, {
    prop: 'self_pickup_support'
  }, {
    prop: 'self_pickup_tips'
  }, {
    prop: 'self_pickup_tips_en'
  }, {
    prop: 'third_party_delivery_cms'
  }],
  paymentInformation: [{
    prop: 'cash_on_delivery',
    back: true
  }, {
    prop: 'paynow_code'
  }, {
    prop: 'paynow_phone_number'
  }, {
    prop: 'paynow_qrcode',
    image: true
  }, {
    prop: 'paynow_type'
  }],
  settlementInformation: [{
    prop: 'automatic_paynow_fee_rate'
  }, {
    prop: 'general_commission_rate'
  }, {
    prop: 'platform_commission_rate'
  }, {
    prop: 'wechat_fee_rate'
  }]
}
